import React, { useState, useEffect } from "react"
import Layouts from "../components/layouts/"
import "../assets/css/global.scss";
import HommeBanner from "../assets/img/home-bg.svg";
import Aos from "aos";
import "aos/dist/aos.css";
import Banner from "../components/banner";

import SEO from "../components/seo";

import ReactFullpage from "@fullpage/react-fullpage";

import Typist from 'react-text-typist';

// markup
const IndexPage = () => {
  useEffect(function () {
    Aos.init({ duration: 800 });
  }, []);
  return (
    <>
      <Layouts>
        <SEO />

        <Banner bannerVector={HommeBanner} >
          <h2>Rapid Solutions for Your <span>Business!</span></h2>
          {/* <h1><Typist sentences={[`Rapid Solutions for Your Business!`, 'Second Sentence', 'Third Sentence']} loop={true} /></h1> */}
        </Banner>
        {/* <ReactFullpage
  scrollOverflow={true}
  sectionsColor={["orange", "purple", "green"]}

  render={({ state, fullpageApi }) => {
    return (
      <div id="fullpage-wrapper">
        <div className="section section1">
          <h3>Section 1</h3>
        </div>
        <div className="section">
          <div className="slide">
            <h3>Slide 2.1</h3>
          </div>
          <div className="slide">
            <h3>Slide 2.2</h3>
          </div>
          <div className="slide">
            <h3>Slide 2.3</h3>
          </div>
        </div>
        <div className="section">
          <h3>Section 3</h3>
          <button onClick={() => fullpageApi.moveTo(1, 0)}>
            Move top
          </button>
        </div>
      </div>
    );
  }}
/> */}
        <div className="container">
          <div className="section" id="sec2">
            <div className="page-section-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 py-3 wow zoomIn" data-aos="zoom-in-right">
                    <div className="img-place text-center">
                      <img src="../img/about-us-bg.svg" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 py-3 wow fadeInRight" data-aos="zoom-out-left">
                    <h2 className="title-section"> Inform the reader<span className="marked"> about</span>  the company</h2>
                    <div className="divider" />
                    <p>We provide marketing services to startups &amp; small business to looking for partner for their digital
                      media, design &amp; dev lead generation &amp; communication.</p>
                    {/*
              <div class="img-place mb-3">
                <img src="../assets/img/testi_image.png" alt="">
              </div>
              */}
                    <a href="/about" className="btn btn-primary hover-shadow">More Details</a>

                  </div>
                </div>
              </div> {/* .container */}
            </div>
          </div> {/* .page-section */}
          <div className="section" id="sec3">
            <div className="page-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 py-3 wow fadeInLeft" data-aos="zoom-in-right">
                    <h2 className="title-section">Quadapp <span className="marked">services</span> as to repair, maintain</h2>
                    <div className="divider" />
                    <p className="">Service businesses are those that provide an activity or the performance of a task with a commercial purpose.</p>
                    <a href="/services" className="btn btn-primary hover-shadow">More Details</a>
                  </div>
                  <div className="col-lg-6 py-3 wow zoomIn" data-aos="zoom-out-left">
                    <div className="img-place text-center">
                      <img src="../img/servies.svg" alt="" />
                    </div>
                  </div>
                </div>
              </div> {/* .container */}
            </div>
          </div>
          {/* .page-section */}
          <div className="section" id="sec4">
            <div className="page-section">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6 py-3 wow zoomIn" data-aos="zoom-in-right">
                    <div className="img-place text-center">
                      <img src="../img/technologies.svg" alt="" />
                    </div>
                  </div>
                  <div className="col-lg-6 py-3 wow fadeInRight" data-aos="zoom-out-left">
                    <h2 className="title-section">Scientific<span className="marked"> knowledge </span> for practical purposes</h2>
                    <div className="divider" />
                    <p>It includes machines (like computers) but also techniques and processes (like the way we produce computer chips).</p>
                    {/*
              <div class="img-place mb-3">
                <img src="../assets/img/testi_image.png" alt="">
              </div>
              */}
                    <a href="/technologies" className="btn btn-primary hover-shadow">More Details</a>
                  </div>
                </div>
              </div> {/* .container */}
            </div> {/* .page-section */}
          </div>

          <section id="home_section6" className="clearfix">
            <div className="container clearfix page-section">
              <h2 className="about-us ">Working Mechanism</h2>
              <div class="divider mx-auto"></div>
              <div className="row Mechanism-section1">
                {/* <div className="col-md-6"  data-aos="zoom-in-right">
                  <img src="../img/Working-Mechanism.svg" alt="" className="Working-Mechanism-img" />
                </div> */}
                <div className="col-md-12">
                  <table className="table table-responsive">
                    <p>We have a number of successful organizations under our belt that are comprehensively designed and
                      developed by our skilled team. Our working methodologies encompass the integration of sectional
                      development and extensive planning. Our working protocols include but are not limited to the
                      following stages of development:
                    </p>
                    <tbody>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon1.svg" alt="" className="Mechanism-icon" />
                        </td>
                        <td>Making proactive research to understand the market demands.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon2.svg" alt="" className="Mechanism-icon" />
                        </td>
                        <td>Identifying the project's potential, loopholes, associated risks, and development
                          modules.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon3.svg" alt="" className="Mechanism-icon" />
                        </td>
                        <td>Consultations between the teams and finalizing the plan of execution.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon4.svg" alt="" className="Mechanism-icon" />
                        </td>
                        <td>Allocating the responsibilities to the team members and assigning individual goals.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon5.svg" alt="" className="Mechanism-icon Mechanism-sectiomn2" />
                        </td>
                        <td>Initiating the ideation process and working towards the Minimum Viable Product.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mechanism-icon6.svg" alt="" className="Mechanism-icon" />
                        </td>
                        <td>Making relentless attempts to ensure the development of a successful business.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
        </div>


        <div className="page-section background-color">
          <div className="container">
          <h2 className="about-us ">Industries we target</h2>
          <div class="divider mx-auto"></div>
          <div className="row mb-4 Industries-section1">
            <div className="col-md-3 Industries-box mr-3">
              <h6 className="Industries-heading">Information Technology</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Be minty, be fresh, and be informed.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Information-Technology.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box mr-3 ">
              <h6 className="Industries-heading">Hospitality</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Make your Tourism seamless.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Hospitality.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box ">
              <h6 className="Industries-heading">Banking</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Manage your banking online.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Banking.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-4 Industries-section2">
            <div className="col-md-3 Industries-box mr-3">
              <h6 className="Industries-heading">Transport</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Make your transport hassle free.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Transport.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box mr-3 ">
              <h6 className="Industries-heading">Manufacture</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Be minty, be fresh, and be informed</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Manufacture.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box ">
              <h6 className="Industries-heading">Infrastructure</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">let your construction display on board.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Infrastructure.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
          </div>

          <div className="row Industries-section3">
            <div className="col-md-3 Industries-box mr-3">
              <h6 className="Industries-heading">Education</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Make your services sleek with technology execution.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Education.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box mr-3 ">
              <h6 className="Industries-heading">Energy</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Track all your processing on sole screen.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Energy.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
            <div className="col-md-3 Industries-box ">
              <h6 className="Industries-heading">Financial Services</h6>
              <div className="Industries-divider" />
              <div className="row">
                <div className="col-md-8 Industries-left">
                  <p className="Industries-para">Manage your finance with just a single click.</p>
                </div>
                <div className="col-md-4 Industries-right">
                  <img src="../img/Financial-Services.svg" alt="" className="Industries-icon" />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>

        <div className="container">
          <section id="home_section6" className="clearfix">
            <div className="container clearfix page-section">
              <h2 className="about-us ">Why Quadapp</h2>
              <div class="divider mx-auto"></div>
              <div className="row Mechanism-section1">
                {/* <div className="col-md-6"  data-aos="zoom-in-right">
                  <img src="../img/Working-Mechanism.svg" alt="" className="Working-Mechanism-img" />
                </div> */}
                <div className="col-md-12">
                  <table className="table table-responsive">
                    <tbody>
                      <tr>
                        <td>
                          <img src="../img/Technology-driven-services.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">Technology driven services:</span> Our services are the evidence of neoteric technology enactment.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/security.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">High end security:</span> Being at the top, security is the foremost essence in our solution to keep business confidential.
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Automation.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">Automation:</span> Automated solutions to reduce red tape, delays and fix responsibilities for efficient, effective and economical solutions.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Mobility.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">Mobility:</span> Mobility is the root of digitization. And we keep Mobility at height for business magnification.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Customization.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">Customization:</span> Horses for Courses is our funda. Customized solutions are what we are excellent at.</td>
                      </tr>
                      <tr>
                        <td>
                          <img src="../img/Experience.svg" alt="" className="w-quad-img" />
                        </td>
                        <td><span className="banner-para">Experience:</span> Experience is the icing on the cake that differentiates us from the rest.</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>


        </div>
      </Layouts>

    </>
  )
}

export default IndexPage
