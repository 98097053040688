import React from "react";
import { Helmet } from "react-helmet";

export default function SEO() {
    return (
        <>
            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                        "@context": "https://schema.org",
                        "@type": "Organization",
                        "url": "https://www.spookytech.com",
                        "name": "Spooky technologies",
                        "contactPoint": {
                            "@type": "ContactPoint",
                            "telephone": "+5-601-785-8543",
                            "contactType": "Customer Support"
                        }
                        }
                    `}
                </script>
            </Helmet>
        </>
    )
}